import { h } from "vue";
import {TABLE_COLUMNS} from './Constants.js'

export const deliveryOption = {
  data() {
    return {
      hasUnSigned: false,
      stateMap: {},
      deliveryColumns: [
        {
          "r-delivery": true,
          prop: "expressState",
          label: "快递",
          width: "70",
          align: "left",
          headerAlign: "left",
          formatter: (row, column, cellValue, index) => {
            if (!cellValue && cellValue !== 0) {
              return "";
            }
            return h(
              "el-tag",
              {
                attrs: {
                  size: "mini",
                  type:
                    cellValue === 0
                      ? "info"
                      : cellValue === 1
                      ? "primary"
                      : "success",
                },
              },
              deliveryStateMapping(cellValue)
            );
          },
        },
        {
          "r-delivery": true,
          prop: "expressId",
          label: "运单号",
          width: "180",
          align: "left",
          headerAlign: "left",
          formatter: (row, column, cellValue, index, e) => {
            const id = cellValue;
            if (id) {
              let url = expressQueryUrl(id);
              if (!url) {
                return id;
              }
              return h("span", null, [
                h(
                  "a",
                  {
                    style: {
                      "margin-left": "10px",
                      "text-decoration-line": "underline",
                      "text-decoration-color": "#4da0ff",
                      color: "#4da0ff",
                      "font-size": "12px",
                    },
                    attrs: {
                      href: url,
                      target: "_blank",
                    },
                  },
                  id
                ),
                this.signButtonVNode(row),
              ]);
            }
            return "";
          },
        },
      ],
    };
  },
  methods: {
    // call on created
    async queryDelivery() {
      const ret = await this.$store.dispatch("routers/deliveryQuery");
      this.stateMap = ret.stateMap;
      const hasUnSigned = Object.keys(this.stateMap).length > 0;
      this.hasUnSigned = hasUnSigned;
    },
    mergeDelivery() {
      // 查询路由器物流状态
      let shouldMerge = this.hasUnSigned && Object.keys(this.stateMap)
        .some(id => this.routerList.find(r => r.routerId === id))
      if (!shouldMerge) {
        setTimeout(() => {
          this.showTableColumn = TABLE_COLUMNS
        }, 500)
        return
      }
      if (!this.showTableColumn.some(v => v['r-delivery'])) {
        this.showTableColumn = [...this.deliveryColumns, ...this.showTableColumn]
      }
      const stateMap = this.stateMap;
      this.routerList.forEach((r) => {
        const state = stateMap[r.routerId];
        if (state) {
          r.expressId = state.expressId || "";
          r.expressState = state.state;
        }
      });
    },
    signButtonVNode({ routerId, expressState }) {
      return h(
        "el-button",
        {
          type: "success",
          attrs: {
            size: "mini",
            type: "danger",
            disabled: expressState !== 1,
          },
          style: {
            "margin-left": "4px",
            padding: '3px 3px'
          },
          on: {
            click: () => {
              this.$confirm("确认已收到路由器？", "签收", {
                confirmButtonText: "已收到",
                cancelButtonText: "未收到",
              }).then(() => {
                this.$store
                  .dispatch("routers/deliverySign", routerId)
                  .then((res) => {
                    this.$message.success("签收成功！");
                    this.queryDelivery().then(() => {
                      this.listRouter();
                    });
                  })
                  .catch((e) => {
                    this.$message.error("签收失败：" + e);
                  });
              });
            },
          },
        },
        "签收"
      );
    },
  },
};

const stateStrMap = ["待邮寄", "邮寄中", "已签收"];
const deliveryStateMapping = (s) => stateStrMap[s] || s;

const expressQueryMap = {
  JD: (id) => `https://www.jdl.com/orderSearch?waybillCodes=${id}`,
  SF: (id) =>
    `https://www.sf-international.com/kr/sc/dynamic_function/waybill/#search/bill-number/${id}`,
};

function expressQueryUrl(expressId = "") {
  const prefix = expressId.substring(0, 2);
  const fn = expressQueryMap[prefix];
  return fn ? fn(expressId) : "";
}
