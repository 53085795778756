<template>
  <models :closeonclickmodal="false" :needcancel="true" :showmodel="show" :title="`修改 WiFi 配置（修改配置需要重启路由器）`"
    canceltext="取消" class="editGroup" confirmtext="确认" @closemodel="close" @confirm="confirm" @open="open">
    <div v-loading="loading" class="main-content">
      <el-form ref="WiFiForm" :model="WifiSet" label-width="100px">
        <fieldset>
          <legend>国内</legend>
          <el-form-item :rules="SSIDRules" label="SSID" prop="domestic.SSID" required>
            <el-input v-model="WifiSet.domestic.SSID" clearable size="mini" style="width: 80%"></el-input>
          </el-form-item>
          <el-form-item :rules="KeyRules" label="Key" prop="domestic.Key" required>
            <el-input v-model="WifiSet.domestic.Key" clearable size="mini" style="width: 80%">
            </el-input>
          </el-form-item>
        </fieldset>

        <fieldset>
          <legend>专线</legend>
          <el-form-item :rules="SSIDRules" label="SSID" prop="fast.SSID" required>
            <el-input v-model="WifiSet.fast.SSID" clearable size="mini" style="width: 80%"></el-input>
          </el-form-item>
          <el-form-item :rules="KeyRules" label="Key" prop="fast.Key" required>
            <el-input v-model="WifiSet.fast.Key" clearable size="mini" style="width: 80%">
            </el-input>
          </el-form-item>
        </fieldset>
      </el-form>
    </div>
  </models>
</template>
<script>
import Models from "@/components/Models/index.vue";

export default {
  props: {
    show: {
      type: Boolean,
    },
    wifiInfo: {
      type: Object,
      require: true
    }
  },
  data() {
    return {
      loading: false,
      WifiSet: {
        fast: {
          SSID: '',
          Key: '',
        },
        domestic: {
          SSID: '',
          Key: '',
        },
        RouterId: ''
      },

      SSIDRules: [
        { required: true, message: 'SSID 不能为空' },
        {
          trigger: 'change',
          validator: (rule, value, callback) => {
            const valid = value && (value.length > 0 && value.length < 32) && !/[\s]+/.test(value)
            if (valid) {
              callback()
            } else {
              callback("ssid 的命名规则： 0 < 长度 < 32, 不能有空格")
            }
          }
        }
      ],
      KeyRules: [
        { required: true, message: 'Key 不能为空' },
        {
          trigger: 'change',
          validator: (rule, value, callback) => {
            const illegal = !value || value.length < 8 || value.trim().length != value.length
            if (illegal) {
              callback('密码规则：至少是 8 位，且前后不能存在空格')
            } else {
              callback()
            }
          }
        }
      ]
    };
  },
  components: {
    Models,
  },
  methods: {

    async confirm() {
      const valid = await this.$refs.WiFiForm.validate().catch(() => { })
      if (!valid) {
        this.$message.warning("请输入必填项！")
        return
      }
      this.loading = true
      this.$emit('wifiChange', this.WifiSet)
      // this.close();
    },
    loadingDone() {
      this.loading = false;
    },
    close() {
      this.loadingDone();
      this.$emit("close");
    },
    open() {
      this.WifiSet = JSON.parse(JSON.stringify(this.wifiInfo))
    },
  },
};
</script>
<style lang="scss" scoped>
.editGroup {
  ::v-deep .el-dialog {
    width: 450px;
  }

  ::v-deep .el-dialog__header {
    padding: 0;
  }

  ::v-deep .el-dialog__footer {
    padding: 0;
  }

  ::v-deep .el-table::before {
    background-color: #fff;
  }


  .main-content {
    margin-top: 10px;

    fieldset {
      border: 1px solid lightgray;
      border-radius: 5px;
      margin-top: 10px;

      legend {
        margin-left: 10px;
        padding: 0px 8px;
        font-weight: bold;
      }
    }
  }
}

.el-input__icon {
  cursor: pointer;
}
</style>
