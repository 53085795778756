<template>
  <models
      :showmodel="show"
      :title="`路由器 ${routerId} 绑定到线路`"
      canceltext="取消"
      :confirmtext="`${lineId ? '重新绑定' : '绑定'}`"
      :needcancel="true"
      @confirm="confirm"
      @closemodel="close"
      class="editGroup"
      @open="open"
      :closeonclickmodal="false"
  >
    <div class="main-content" v-loading="bindLoading">
      <el-form label-width="100px">
        <el-form-item label="线路" required>
          <el-select size="mini" style="width: 80%" v-model="lineToBind" filterable>
            <el-option v-for="(line) in lineList" :label="`${line.name}@[${line.ip}]`" :value="line.id" :key="line.id" :disabled="line.bindRouter"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="bingRetMessage">{{bindRetMessage}}</div>
    </div>
  </models>
</template>

<script>
import Models from "@/components/Models/index.vue";
import {getToken} from "@/utils/auth.js";
export default {
  name: "BindLine",
  components: {
    Models
  },
  props: {
    show: {
      type: Boolean,
    },
    routerId: {
      type: String,
      required: true
    },
    lineId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      lineList: [],
      lineToBind: '',
      bindRetMessage: '',
      bindLoading: false
    }
  },
  methods: {
    async confirm() {
      if (!this.lineToBind) {
        this.$message.warning("绑定的线路必须选择！")
        return;
      }
      if (this.lineToBind === this.lineId) {
        this.$message.warning('当前线路已绑定，无需再次绑定!')
        return
      }
      if (this.bindLoading) {
        this.$message.warning('当前正在绑定，请勿重复绑定！')
        return
      }
      this.bindLoading = true;
      this.$emit('chooseLine', {RouterId: this.routerId, LineId: this.lineToBind})
      // 父组件请求成功后再关闭
      // this.close();
    },
    close() {
      this.loadingDone()
      this.$emit("close");
    },
    loadingDone(errMessage = '') {
      this.bindLoading = false
      this.bindRetMessage = errMessage
    },
    open() {
      this.lineList = []
      this.lineToBind = ''
      this.getLineList()
    },

    getLineList() {
      const hasToken = getToken();
      this.$store
          .dispatch("line/getlinelist", { Authorization: hasToken })
          .then((res) => {
            if (res.lineInfos !== null && res.lineInfos.length > 0) {
              this.lineList = res.lineInfos
              const bound = this.lineList.find(li => li.id === this.lineId)
              if (bound) {
                // 设定选中的为当前绑定的 line
                this.lineToBind = this.lineId
              }
            }
          })
          .catch(err => {
            this.$message.error(`获取待绑定的线路失败： ${err}`)
          });
    },
  }
}
</script>

<style lang="scss" scoped>
.editGroup {
  ::v-deep .el-dialog {
    width: 450px;
    height: 290px;
  }

  ::v-deep .el-dialog__header {
    padding: 0;
  }

  ::v-deep .el-dialog__footer {
    padding: 0;
  }

  ::v-deep .el-dialog__body {
    height: 120px;
  }

  ::v-deep .el-table::before {
    background-color: #fff;
  }


  .main-content {
    margin-top: 20px;
  }

  .bingRetMessage {
    font-weight: bold;
    color: #e6a23c;
  }
}
</style>
