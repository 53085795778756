<template>
  <models
      :showmodel="show"
      title="修改路由名称"
      canceltext="取消"
      confirmtext="确认"
      :needcancel="true"
      @confirm="confirm"
      @closemodel="close"
      @open="open"
      class="editGroup"
  >
    <div class="main-content" v-loading="loading">
      <el-form label-width="100px" :model="router" ref="routerForm">
        <el-form-item label="Key" prop="name" required :rules="nameRules">
          <el-input size="mini" style="width: 80%" v-model="router.name" clearable></el-input>
        </el-form-item>
      </el-form>
    </div>
  </models>
</template>

<script>
import Models from "@/components/Models/index.vue";
export default {
  name: "RouterUpdater",
  props: {
    show: {
      type: Boolean,
    },
    name: {
      type: String,
      require: true
    }
  },
  data() {
    return {
      loading: false,
      router: {
        name: ''
      },

      nameRules: [
        {required: true, message: '名称不能为空'},
        {trigger: 'change',
          validator: (rule, value, callback) => {
            const valid = value && (value.length > 0 && value.length < 32)
            if (valid) {
              callback()
            } else {
              callback("路由器名称规则： 0 < 长度 < 32")
            }
          }
        }
      ]
    };
  },
  components: {
    Models,
  },
  methods: {
    async confirm() {
      const valid = await this.$refs.routerForm.validate().catch(() => {})
      if (!valid) {
        this.$message.warning("请输入必填项！")
        return
      }
      this.loading = true
      this.$emit('change', {name: this.router.name})
    },
    loadingDone() {
      this.loading = false;
    },
    close() {
      this.loadingDone();
      this.$emit("close");
    },
    open() {
      this.router.name = this.name
    }
  }
}
</script>

<style scoped lang="scss">
.editGroup {
  ::v-deep .el-dialog {
    width: 450px;
    height: 290px;
  }

  ::v-deep .el-dialog__header {
    padding: 0;
  }

  ::v-deep .el-dialog__footer {
    padding: 0;
  }

  ::v-deep .el-dialog__body {
    height: 120px;
  }

  ::v-deep .el-table::before {
    background-color: #fff;
  }


  .main-content {
    margin-top: 20px;
  }
}
</style>
